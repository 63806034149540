import { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Radio,
  RadioGroup,
  Stack,
  FormLabel,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { AiOutlineCheckCircle } from "react-icons/ai";

import { formatPrice } from "../utils/formatPrice";
import { PizzaDetailsFormData2, TEdge } from "../Pages/PizzaDetails";

interface EdgePizzaContainerProps {
  // complement: any;
  name: string;
  edges: TEdge[];
}

function EdgePizzaContainer({
  name,
  edges,
}: EdgePizzaContainerProps): JSX.Element {
  // const { max, min, name, options } = complement;
  const [isSelected, setIsSelected] = useState(false);
  const { control, setValue } = useFormContext<PizzaDetailsFormData2>();

  return (
    <>
      <Flex
        p="1rem"
        borderBottom="1px solid #d5d5d5"
        bg="#f3f5f7"
        position="sticky"
        zIndex="sticky"
        top="0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w="80%">
          <Text fontWeight="bold" fontSize="18px" py={4}>
            {name}
          </Text>
        </Box>
        {isSelected ? (
          <AiOutlineCheckCircle size={30} color="green" />
        ) : (
          <Box
            fontSize="13px"
            bg="gray.600"
            color="white"
            borderRadius="md"
            px={1}
          >
            Obrigatório
          </Box>
        )}
      </Flex>
      {edges.map((edge) => (
        <Controller
          key={edge.id}
          name="edge.id"
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <FormLabel
                  // necessario concatenar, cada bloco possui ids iguais
                  // htmlfor nao funciona
                  htmlFor={edge.id}
                  display="flex"
                  flexDirection="row"
                  p="1rem"
                  alignItems="center "
                  justifyContent="space-between"
                  bg="white"
                  borderBottom="1px solid #d5d5d5"
                >
                  <Box>
                    <Text>{edge.name}</Text>
                    {/* <Text>{flavor.description}</Text> */}
                    <Text>{`+ ${formatPrice(edge.price)}`}</Text>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {/* <Image
                      src={flavor.urlImage}
                      loading="lazy"
                      borderRadius="0.5rem"
                      boxSize="50px"
                      objectFit="cover"
                      mr={1}
                    /> */}
                    <Radio
                      id={edge.id}
                      colorScheme="yellow"
                      size="lg"
                      value={edge.id}
                      onChange={() => {
                        setValue("edge.name", edge.name);
                        setValue("edge.productId", edge.productId);
                        setValue("edge.unitPrice", edge.price);
                        setValue("edge.quantity", 1);
                        setValue("edge.price", 1 * edge.price);
                        setIsSelected(true);
                      }}
                    />
                  </Box>
                </FormLabel>
              </Stack>
            </RadioGroup>
          )}
        />
      ))}
    </>
  );
}

export { EdgePizzaContainer };
