import { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { CgChevronLeft } from "react-icons/cg";
import { RiDeleteBinLine } from "react-icons/ri";

import { useCart } from "../store/useCart";
import { formatPrice } from "../utils/formatPrice";

import { delay } from "./ItemDetails";
import { getFlavorText } from "./PizzaDetails";

function ShoppingCartPage(): JSX.Element {
  const {
    cart,
    totalCart,
    emptyCart,
    incrementCart,
    incrementPizzaCart,
    decreaseCart,
    decreasePizzaCart,
    removeFromCart,
    removePizzaFromCart,
  } = useCart();

  const navigate = useNavigate();
  const toast = useToast();

  // scroll to the top after render
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // taxa de entrega
  const subTotal = totalCart();
  const deliveryFee = 0;
  const total = subTotal + deliveryFee;

  async function handleEmptyCart(): Promise<void> {
    emptyCart();
    toast({
      title: "Sua sacola está vazia!",
      status: "success",
      position: "top",
      duration: 1500,
    });
    await delay();
    navigate("/menu");
  }

  function handleDecrementcart(
    id: string,
    quantity: number,
    type: "DEFAULT" | "PIZZA"
  ): void {
    if (quantity > 1) {
      // eslint-disable-next-line no-unused-expressions
      type === "DEFAULT" ? decreaseCart(id) : decreasePizzaCart(id);
    } else {
      const lengthAfter = cart.length - 1;
      // eslint-disable-next-line no-unused-expressions
      type === "DEFAULT" ? removeFromCart(id) : removePizzaFromCart(id);

      if (lengthAfter === 0) {
        toast({
          title: "Sua sacola está vazia!",
          status: "success",
          position: "top",
          duration: 1500,
        });
        navigate("/menu");
      }
    }
  }

  function handleIncrementCart(id: string, type: "DEFAULT" | "PIZZA"): void {
    switch (type) {
      case "DEFAULT":
        incrementCart(id);
        break;
      case "PIZZA":
        incrementPizzaCart(id);
        break;
      default:
        break;
    }
  }

  return (
    <Box
      w={{ base: "full", md: "780px" }}
      bg="white"
      display="flex"
      flexDirection="column"
      p="1rem"
    >
      <Flex
        alignItems=" center"
        justifyContent="space-between"
        // mb={4}
        h="4rem"
        position="sticky"
        top="0"
        zIndex="sticky"
        bg="white"
      >
        <Link to="/menu">
          {/* <CgChevronLeft size={25} color="primary.500" /> */}
          <Icon as={CgChevronLeft} h={6} w={6} color="primary.500" />
        </Link>
        <Heading as="h3" size="md" ml={14}>
          <Center>Meu pedido</Center>
        </Heading>
        <Button
          colorScheme="primary"
          variant="link"
          size="sm"
          onClick={() => handleEmptyCart()}
        >
          Limpar pedido
        </Button>
      </Flex>
      <Link to="/menu">
        <Button
          colorScheme="primary"
          mb={4}
          variant="outline"
          isFullWidth
          mt="50px"
        >
          Adicionar mais itens
        </Button>
      </Link>
      <Text fontWeight="bold">Itens </Text>
      <Divider mb={4} />
      {cart.map((element) => {
        switch (element.type) {
          case "DEFAULT":
            return (
              <Box key={element.item.id} mb={4}>
                <Text color="gray.900">{element.item.name}</Text>
                {element.item.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.item.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.item.options.map((option) => (
                    <Text key={option.name} fontSize="14px">
                      {`${option.quantity} ${option.name}`}
                    </Text>
                  ))}
                </Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.900">
                    {formatPrice(element.item.total)}
                  </Text>
                  <Flex maxW="100px" alignItems="center">
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleDecrementcart(
                          element.item.id,
                          element.item.quantity,
                          "DEFAULT"
                        )
                      }
                      size="sm"
                    >
                      {element.item.quantity > 1 ? "-" : <RiDeleteBinLine />}
                    </Button>
                    <Box p={2}> {element.item.quantity}</Box>
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleIncrementCart(element.item.id, "DEFAULT")
                      }
                      size="sm"
                    >
                      +
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            );
          case "PIZZA":
            return (
              <Box key={element.pizza.id} mb={4}>
                <Text color="gray.900">{`${
                  element.pizza.size.name
                } ${getFlavorText(element.pizza.size.numberOfFlavors)}`}</Text>
                {element.pizza.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.pizza.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.pizza.flavors.map((flavor, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Text key={index.toString()} fontSize="14px">
                      {`${flavor.quantity} ${
                        element.pizza.size.numberOfFlavors > 1
                          ? `1/${element.pizza.size.numberOfFlavors} ${flavor.name}`
                          : flavor.name
                      }`}
                    </Text>
                  ))}
                  <Text key={element.pizza.edge.name} fontSize="14px">
                    {`${element.pizza.edge.quantity} ${element.pizza.edge.name}`}
                  </Text>
                </Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.900">
                    {formatPrice(element.pizza.total)}
                  </Text>
                  <Flex maxW="100px" alignItems="center">
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleDecrementcart(
                          element.pizza.id,
                          element.pizza.quantity,
                          "PIZZA"
                        )
                      }
                      size="sm"
                    >
                      {element.pizza.quantity > 1 ? "-" : <RiDeleteBinLine />}
                    </Button>
                    <Box p={2}> {element.pizza.quantity}</Box>
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleIncrementCart(element.pizza.id, "PIZZA")
                      }
                      size="sm"
                    >
                      +
                    </Button>
                  </Flex>
                </Flex>
              </Box>
            );

          default:
            return <div key={v4()}>Elemento do carrinho invalido</div>;
        }
      })}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem 1rem",
          // width: "100%",
          maxWidth: "780px",
          // height: "150px",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          borderTop: "1px solid #d5d5d5",
          background: "#ffff",
        }}
      >
        <Box width="100%" mb={4}>
          {/* subtotal */}
          <Flex justifyContent="space-between">
            <Text fontSize="16px" color="gray.900">
              Subtotal
            </Text>
            <Text fontSize="16x" color="gray.900">
              {formatPrice(subTotal)}
            </Text>
          </Flex>
          {/* taxa de entrega */}
          <Flex justifyContent="space-between">
            <Text fontSize="16px" color="gray.900">
              Taxa de entrega
            </Text>
            <Text fontSize="16px" color="gray.900">
              {formatPrice(deliveryFee)}
            </Text>
          </Flex>
          {/* total */}
          <Flex justifyContent="space-between">
            <Text fontSize="18px" fontWeight="bold" color="gray.900">
              Total
            </Text>
            <Text fontSize="18px" fontWeight="bold" color="gray.900">
              {formatPrice(total)}
            </Text>
          </Flex>
        </Box>
        <Button
          colorScheme="primary"
          isFullWidth
          height="3rem"
          borderRadius="lg"
          onClick={() => navigate("/order-details")}
        >
          Concluir pedido
        </Button>
      </div>
    </Box>
  );
}

export { ShoppingCartPage };
