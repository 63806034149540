import { useEffect, useMemo } from "react";
import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react";
import {
  Element,
  Events,
  Link as LinkReactScroll,
  scrollSpy,
} from "react-scroll";
import { Link as LinkRouterDom, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";

import "../styles.css";

import { BiStore } from "react-icons/bi";
import { BsTelephone, BsCart2 } from "react-icons/bs";
import {
  MdOutlinePlace,
  MdArrowForwardIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

import logo2 from "../assets/CapaSistema.jpg";

import { TCategory, TPrice } from "../types";

import { useCart } from "../store/useCart";
import { formatPrice } from "../utils/formatPrice";
import { useFetch } from "../hooks/useFetch";

import { ItemCard } from "../Components/ItemCard";
import { Address } from "../Components/Address";
import { useInfo } from "../contexts/info";
import { Info } from "../Components/Info";
import { SizeCard } from "../Components/SizeCard";
import { OpenningHour } from "../Components/OpenningHour";

function minPriceSize(prices: TPrice[]): number {
  return Math.min(...prices.map((price) => price.originalPrice));
}

function Menu(): JSX.Element {
  const { data: categories, isLoading } = useFetch<TCategory[]>(
    `/companies/${process.env.REACT_APP_COMPANYID}/menu`
  );

  const { info, onOpen, isOpen } = useInfo();

  const { cart, quantityOftemsInCart, totalCart } = useCart();

  const navigate = useNavigate();

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {});
    Events.scrollEvent.register("end", () => {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const categoriesNav = useMemo(
    () =>
      categories?.map((category: any) => ({
        id: category.id,
        name: category.name,
      })),
    [categories]
  );

  return (
    <Box bg="white" maxW="780px">
      {/* Logo */}
      <Box
        h="150px"
        backgroundImage={logo2}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        mb="1rem"
        position="relative"
      />
      <Flex
        position="absolute"
        top="120px"
        left="1rem"
        bg="white"
        color="black"
        borderRadius="md"
        px={1}
        alignItems="center"
        onClick={onOpen}
      >
        <Text>Sobre a loja</Text>
        <MdArrowForwardIos size={16} />
      </Flex>

      {/* Infos */}
      <Flex p="1rem" w="100%" justifyContent="space-between" mb="1rem">
        <Box>
          <Flex alignItems="center">
            <BiStore />
            <Text ml="0.5rem"> {info.tradeName}</Text>
          </Flex>
          <Flex alignItems="center">
            <BsTelephone />
            <Text ml="0.5rem">{info.phone.phoneNumber}</Text>
          </Flex>
          <Flex alignItems="center">
            <MdOutlinePlace />
            <Text ml="0.5rem">{`${info.address.street}, ${info.address.number} - ${info.address.neighborhood}`}</Text>
          </Flex>

          <Flex alignItems="center" gap={2} mt={2}>
            <Text fontWeight="bold">Entregar em</Text>
            <MdOutlineKeyboardArrowDown size={25} />
          </Flex>
          <Address />
        </Box>

        <OpenningHour />
      </Flex>

      {isLoading ? (
        <Flex w="100%" justifyContent="center">
          <BeatLoader size={12} color="red" />
        </Flex>
      ) : (
        <>
          {/* Navbar */}
          <HStack
            as="nav"
            position="sticky"
            top="0"
            zIndex="sticky"
            bg="white"
            overflow="scroll"
            p="1rem"
          >
            {/* Navbar(links) */}
            {categoriesNav?.length !== 0 &&
              categoriesNav?.map((category) => (
                <LinkReactScroll
                  key={category.id}
                  activeClass="active"
                  style={{
                    padding: " 0.5rem 1rem 0.5rem 1rem",
                    border: "1px solid #d5d5d5",
                    borderRadius: "0.5rem",
                    whiteSpace: "nowrap",
                  }}
                  to={category.name}
                  spy
                  smooth
                  duration={300}
                  offset={-70}
                >
                  {category.name}
                </LinkReactScroll>
              ))}
          </HStack>

          {/* Lista as categorias */}
          {categories?.map((category) => {
            switch (category.type) {
              case "DEFAULT":
                return (
                  <Element key={category.id} name={category.name}>
                    {/* Header */}
                    <Flex
                      p="1rem"
                      borderBottom="1px solid #d5d5d5"
                      bg="#f3f5f7"
                    >
                      <Text fontWeight="semibold" fontSize="18px">
                        {category.name}
                      </Text>
                    </Flex>
                    {/* Items */}
                    {category.items &&
                      category.items.map((item) => (
                        <LinkRouterDom
                          key={item.productId}
                          to={`/details/${item.productId}`}
                          state={{
                            name: item.name,
                            description: item.description,
                            urlImage: item.urlImage,
                            originalPrice: item.originalPrice,
                          }}
                        >
                          <ItemCard
                            title={item.name}
                            description={item.description}
                            price={item.originalPrice}
                            urlImage={item.urlImage}
                          />
                        </LinkRouterDom>
                      ))}
                  </Element>
                );
              case "PIZZA":
                return (
                  <Element key={category.id} name={category.name}>
                    <Flex
                      p="1rem"
                      borderBottom="1px solid #d5d5d5"
                      bg="#f3f5f7"
                    >
                      <Text fontWeight="semibold" fontSize="18px">
                        {category.name}
                      </Text>
                    </Flex>
                    {
                      category.pizza.sizes.map((size) =>
                        Array.from(
                          { length: size.numberOfFlavors },
                          (_, index) => ({
                            ...size,
                            numberOfFlavors: index + 1,
                          })
                        ).map((newSize) => (
                          <LinkRouterDom
                            key={`${newSize.name}${newSize.numberOfFlavors}`} // Mudar isso
                            to={`/pizza-details/${newSize.pizzaId}/${newSize.id}`}
                            state={{
                              name: newSize.name,
                              productId: newSize.productId,
                              numberOfFlavors: newSize.numberOfFlavors,
                              minPrice: minPriceSize(newSize.prices),
                            }}
                          >
                            <SizeCard
                              title={newSize.name}
                              numberOfFlavors={newSize.numberOfFlavors}
                              price={minPriceSize(newSize.prices)}
                            />
                          </LinkRouterDom>
                        ))
                      )

                      // ))
                    }
                  </Element>
                );
              default:
                return <div>Categoria invalida</div>;
            }
          })}
        </>
      )}

      {/* espaco em branco no pe da pagina(para o footer nao tampar) */}
      <div style={{ background: "white", height: "60px", width: "100%" }} />

      {quantityOftemsInCart() > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1rem 1rem",
            // width: "100%",
            maxWidth: "780px",
            height: "60px",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 100,
            borderTop: "1px solid #d5d5d5",
            background: "#ffff",
          }}
        >
          <Button
            colorScheme="primary"
            width="full"
            height="3rem"
            alignItems="center"
            justifyContent="space-between"
            px={4}
            borderRadius="lg"
            onClick={() => navigate("/shopping-cart")}
          >
            <Flex alignItems="center" gap={3}>
              <BsCart2 size={20} />
              <Flex
                p={1}
                bg="white"
                color="gray.500"
                height="23px"
                w="23px"
                borderRadius="50%"
                alignItems="center"
                justifyContent="center"
              >
                {cart.length}
              </Flex>
            </Flex>
            <Text>Meu pedido</Text>
            <Text>{formatPrice(totalCart())}</Text>
          </Button>
        </div>
      )}

      {isOpen && <Info />}
    </Box>
  );
}

export { Menu };

/* <Link
          activeClass="active"
          style={{ padding: "1rem" }}
          to="ofertas"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Ofertas
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="pizzas"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Pizzas Tradicionais
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="calzones"
          spy
          smooth
          duration={300}
          offset={-70}
          // eslint-disable-next-line react/jsx-no-bind
          onSetActive={handleOnSetActive}
        >
          Calzones
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="bebidas"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Bebidas
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="-"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Sobremesas
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="-"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Pizzas Doces
        </Link>
        <Link
          activeClass="active"
          style={{ padding: "1rem", whiteSpace: "nowrap" }}
          to="bebidas"
          spy
          smooth
          duration={300}
          offset={-70}
        >
          Combos
        </Link> */
/** 

{
  /* <Element name="Ofertas" style={{ padding: "4rem 0 0 0 0" }}>
        <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
          <Text fontWeight="bold" fontSize="20px">
            Ofertas
          </Text>
        </Flex>

        <CategoryCard
          title="Combo dia dos namorados"
          description="1 pizza + 1 refri + 2 pizzas doces"
          price={20.0}
          to="/calzones"
          urlFoto={promo02}
        />
        <CategoryCard
          title="Combo dia dos namorados"
          description="1 pizza + 1 refri + 2 pizzas doces"
          price={20.0}
          to="/calzones"
          urlFoto={promo03}
        />

        <CategoryCard
          title="Combo dia dos namorados"
          description="1 pizza + 1 refri + 2 pizzas doces"
          price={20.0}
          to="/calzones"
          urlFoto={promo04}
        />
      </Element>

      <Element name="Pizzas Tradicionais">
        <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
          <Text fontWeight="bold" fontSize="20px">
            Pizzas Tradicionais
          </Text>
        </Flex>

        <CategoryCard to="pizzas" title="Pizza media" price={30.5} />
        <CategoryCard to="pizzas" title="Pizza media 2 sabores" price={30.5} />
        <CategoryCard to="pizzas" title="Pizza grande" price={30.5} />
        <CategoryCard to="pizzas" title="Pizza grande 2 sabores" price={30.5} />
      </Element>

      <Element name="calzones">
        <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
          <Text fontWeight="bold" fontSize="20px">
            Calzones
          </Text>
        </Flex>

        <CategoryCard to="calzones" title="Calzones Salgados" price={20.6} />
        <CategoryCard to="calzones" title="Calzones Doces" price={12.0} />
      </Element>

      <Element name="Bebidas" style={{ padding: "4rem 0 0 0 0" }}>
        <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
          <Text fontWeight="bold" fontSize="20px">
            Bebidas
          </Text>
        </Flex>

        <CategoryCard to="drinks" title="Coca 2l" price={10.5} />
        <CategoryCard to="drinks" title="Coca 500l" price={10.5} />
        <CategoryCard
          to="drinks"
          title="Guarana antartica 290ml"
          price={10.5}
        />
        <CategoryCard to="drinks" title="Fanta" price={10.5} />
        <CategoryCard to="drinks" title="Coca 2l" price={10.5} />
      </Element> */

// if (category.type === "DEFAULT") {
//   return (
//     <Element key={category.id} name={category.name}>
//       <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
//         <Text fontWeight="bold" fontSize="20px">
//           {category.name}
//         </Text>
//       </Flex>
//       {category.items &&
//         category.items.map((item) => (
//           <LinkRouterDom
//             key={item.id}
//             to={`/details/${item.id}`}
//             // state={{ sizeId: item.originalPrice], category }}
//           >
//             <CategoryCard
//               title={item.name}
//               description={item.description}
//               price={item.originalPrice}
//               urlFoto={item.urlImage}
//             />
//           </LinkRouterDom>
//         ))}
//     </Element>
//   );
// }
// // se pizza, lista os tamanhos( em vez dos items)/
// return (
//   <Element key={category.id} name={category.name}>
//     <Flex p="1rem" borderBottom="1px solid #d5d5d5" bg="#f3f5f7">
//       <Text fontWeight="bold" fontSize="20px">
//         {category.name}
//       </Text>
//     </Flex>
//     {/* {category.sizes.map((size) => (
//       <LinkRouterDom
//         key={size.id}
//         to={`/details/${category.id}`}
//         state={{
//           sizeId: size.id,
//           numberOfFlavors: size.flavors,
//           category,
//         }}
//       >
//         <CategoryCard title={size.name} price={20} />
//       </LinkRouterDom>
//     ))} */}
//   </Element>
// );

/* <Element key={category.id} name={category.name}>
<Flex
  p="1rem"
  borderBottom="1px solid #d5d5d5"
  bg="#f3f5f7"
>
  <Text fontWeight="bold" fontSize="20px">
    {category.name}
  </Text>
</Flex>
{category.pizza.sizes.map((size) => (
  <LinkRouterDom
    key={size.id}
    to={`/pizza-details/${size.pizzaId}/${size.id}`}
    state={{
      sizeId: size.id,
      name: size.name,
      numberOfFlavors: size.numberOfFlavors,
      minPrice: minPrice(size.prices),
    }}
  >
    <PizzaCard
      title={`${size.name} ${size.numberOfFlavors} sabores`}
      price={minPrice(size.prices)}
    />
  </LinkRouterDom>
))}
</Element> */
