import { RadioProps, Flex, Img, Radio, Text } from "@chakra-ui/react";

interface PaymentCardProps extends RadioProps {
  name: string;
  brand: string;
  isSelected: boolean;
}
function PaymentCard({
  name,
  isSelected,
  ...rest
}: PaymentCardProps): JSX.Element {
  return (
    <Flex
      w="100%"
      border="1px solid"
      borderColor={isSelected ? "#F95706" : "#d5d5d5"}
      p="1rem"
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex>
        <Img src="" />
        <Text ml={4} color="gray.500" fontWeight="normal">
          {name}
        </Text>
      </Flex>
      <Radio colorScheme="primary" {...rest} />
    </Flex>
  );
}

export { PaymentCard };
