import { v4 } from "uuid";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export type CartItem = {
  id: string; // id do item no pedido
  productId: string;
  name: string;
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  observations: string;
  totalOptions: number;

  options: {
    id: string;
    productId: string;
    name: string;
    cod: string;
    quantity: number;
    unitPrice: number;
    price: number; // price = quantity * unitPrice
  }[];

  total: number; // price + totalOptions
};

export type CartPizza = {
  id: string;
  pizzaId: string;
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  observations: string;
  totalOptions: number;
  edge: {
    id: string;
    productId: string;
    name: string;
    price: number;
    quantity: number;
    unitPrice: number;
  };
  flavors: {
    id: string;
    name: string;
    price: number;
    productId: string;
    quantity: number;
    unitPrice: number;
  }[];
  size: {
    id: string;
    name: string;
    numberOfFlavors: number;
    productId: string;
  };
  total: number; // price + totalOptions
};

interface CartState {
  cart: {
    type: "PIZZA" | "DEFAULT";
    item: CartItem; // se item, pizza é null
    pizza: CartPizza; // se pizza, item é null
  }[];
  addTocart: (item: Omit<CartItem, "id">) => void;
  addPizzaToCart: (pizza: Omit<CartPizza, "id">) => void;
  removeFromCart: (id: string) => void;
  removePizzaFromCart: (id: string) => void;
  incrementCart: (id: string) => void;
  incrementPizzaCart: (id: string) => void;
  decreaseCart: (id: string) => void;
  decreasePizzaCart: (id: string) => void;
  emptyCart: () => void;
  totalCart: () => number;
  quantityOftemsInCart: () => number;
}

export const useCart = create<CartState>()(
  // persist(
  (set, get) => ({
    cart: [],

    addTocart: (item) =>
      set((state) => ({
        cart: [
          ...state.cart,
          {
            type: "DEFAULT",
            item: { ...item, id: v4() },
            pizza: {} as CartPizza, // gambiarra( testar null na undefined na tipagem)
          },
        ],
      })),

    addPizzaToCart: (pizza) =>
      set((state) => ({
        cart: [
          ...state.cart,
          {
            type: "PIZZA",
            item: {} as CartItem,
            pizza: { ...pizza, id: v4() }, // gambiarra( testar null na undefined na tipagem)
          },
        ],
      })),
    removeFromCart: (id) =>
      set((state) => ({
        cart: state.cart.filter((element) => element.item.id !== id),
      })),

    removePizzaFromCart: (id) =>
      set((state) => ({
        cart: state.cart.filter((element) => element.pizza.id !== id),
      })),

    emptyCart: () => set(() => ({ cart: [] })),

    incrementCart: (id) =>
      set((state) => ({
        cart: state.cart.map((element) => {
          if (element.item.id === id) {
            const newQuantity = element.item.quantity + 1;
            const newPrice = newQuantity * element.item.unitPrice;
            const newTotaOptions = newQuantity * element.item.totalOptions;
            const newTotal = newPrice + newTotaOptions;
            return {
              ...element,
              item: {
                ...element.item,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              },
            };
          }

          return element;
        }),
      })),

    incrementPizzaCart: (id) =>
      set((state) => ({
        cart: state.cart.map((element) => {
          if (element.pizza.id === id) {
            const newQuantity = element.pizza.quantity + 1;
            const newPrice = newQuantity * element.pizza.unitPrice;
            const newTotaOptions = newQuantity * element.pizza.totalOptions;
            const newTotal = newPrice + newTotaOptions;
            return {
              ...element,
              pizza: {
                ...element.pizza,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              },
            };
          }

          return element;
        }),
      })),

    decreaseCart: (id) =>
      set((state) => ({
        cart: state.cart.map((element) => {
          if (element.item.id === id) {
            const newQuantity = element.item.quantity - 1;
            const newPrice = newQuantity * element.item.unitPrice;
            const newTotaOptions = newQuantity * element.item.totalOptions;
            const newTotal = newPrice + newTotaOptions;
            return {
              ...element,
              item: {
                ...element.item,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              },
            };
          }

          return element;
        }),
      })),

    decreasePizzaCart: (id) =>
      set((state) => ({
        cart: state.cart.map((element) => {
          if (element.pizza.id === id) {
            const newQuantity = element.pizza.quantity - 1;
            const newPrice = newQuantity * element.pizza.unitPrice;
            const newTotaOptions = newQuantity * element.pizza.totalOptions;
            const newTotal = newPrice + newTotaOptions;
            return {
              ...element,
              pizza: {
                ...element.pizza,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              },
            };
          }

          return element;
        }),
      })),

    totalCart: () =>
      get().cart.reduce((acc, element) => {
        if (element.type === "DEFAULT") {
          return acc + element.item.total;
        }
        return acc + element.pizza.total;
      }, 0),
    quantityOftemsInCart: () => get().cart.length,
  })

  // {
  //   name: "@projeto-pizzaria:cart",
  //   onRehydrateStorage: () => {
  //     console.log("Cart: hydration starts");

  //     // optional
  //     return (stat, error) => {
  //       if (error) {
  //         console.log("an error happened during hydration of cart", error);
  //       } else {
  //         console.log("Cart: hydration finished");
  //       }
  //     };
  //   },
  // }
  // )
);

/**
 export type CartItem = {
  id: string; // id do item no pedido
  productId: string;
  name: string;
  cod: string;
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  observations: string;
  totalOptions: number;

  options: {
    id: string;
    productId: string;
    name: string;
    cod: string;
    quantity: number;
    unitPrice: number;
    price: number; // price = quantity * unitPrice
  }[];

  total: number; // price + totalOptions
};

interface CartState {
  cart: CartItem[];
  addTocart: (item: Omit<CartItem, "id">) => void;
  removeFromCart: (id: string) => void;
  incrementCart: (id: string) => void;
  decreaseCart: (id: string) => void;
  emptyCart: () => void;
  totalCart: () => number;
  quantityOftemsInCart: () => number;
}

export const useCart = create<CartState>()(
  persist(
    (set, get) => ({
      cart: [],
      addTocart: (item) =>
        set((state) => ({ cart: [...state.cart, { ...item, id: v4() }] })),
      removeFromCart: (id) =>
        set((state) => ({ cart: state.cart.filter((item) => item.id !== id) })),
      emptyCart: () => set(() => ({ cart: [] })),
      incrementCart: (id) =>
        set((state) => ({
          cart: state.cart.map((item) => {
            if (item.id === id) {
              const newQuantity = item.quantity + 1;
              const newPrice = newQuantity * item.unitPrice;
              const newTotaOptions = newQuantity * item.totalOptions;
              const newTotal = newPrice + newTotaOptions;
              return {
                ...item,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              };
            }

            return item;
          }),
        })),
      decreaseCart: (id) =>
        set((state) => ({
          cart: state.cart.map((item) => {
            if (item.id === id) {
              const newQuantity = item.quantity - 1;
              const newPrice = newQuantity * item.unitPrice;
              const newTotaOptions = newQuantity * item.totalOptions;
              const newTotal = newPrice + newTotaOptions;
              return {
                ...item,
                quantity: newQuantity,
                price: newPrice,
                total: newTotal,
              };
            }

            return item;
          }),
        })),
      totalCart: () => get().cart.reduce((acc, item) => acc + item.total, 0),
      quantityOftemsInCart: () => get().cart.length,
    }),

    { name: "@projeto-pizzaria:cart" }
  )
);
 */
