import { useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { v4 } from "uuid";

import { CgChevronLeft } from "react-icons/cg";
import { RiDeleteBinLine } from "react-icons/ri";

import { useCart } from "../store/useCart";
import { formatPrice } from "../utils/formatPrice";

import { delay } from "./ItemDetails";
import { getFlavorText } from "./PizzaDetails";

function OrderPlaced(): JSX.Element {
  const { cart, totalCart } = useCart();

  // scroll to the top after render
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  // taxa de entrega
  const subTotal = totalCart();
  const deliveryFee = 0;
  const total = subTotal + deliveryFee;

  return (
    <Box
      w={{ base: "full", md: "780px" }}
      bg="white"
      display="flex"
      flexDirection="column"
      p="1rem"
    >
      <Flex
        alignItems=" center"
        justifyContent="center"
        // mb={4}
        h="4rem"
        position="sticky"
        zIndex="sticky"
        bg="white"
      >
        <Heading as="h3" size="md" ml={14}>
          <Center>Meu pedido</Center>
        </Heading>
      </Flex>

      <Text fontWeight="bold">Itens </Text>
      <Divider mb={4} />
      {cart.map((element) => {
        switch (element.type) {
          case "DEFAULT":
            return (
              <Box key={element.item.id} mb={4}>
                <Text color="gray.900">{element.item.name}</Text>
                {element.item.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.item.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.item.options.map((option) => (
                    <Text key={option.name} fontSize="14px">
                      {`${option.quantity} ${option.name}`}
                    </Text>
                  ))}
                </Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.900">
                    {formatPrice(element.item.total)}
                  </Text>
                  {/* right */}
                </Flex>
              </Box>
            );
          case "PIZZA":
            return (
              <Box key={element.pizza.id} mb={4}>
                <Text color="gray.900">{`${
                  element.pizza.size.name
                } ${getFlavorText(element.pizza.size.numberOfFlavors)}`}</Text>
                {element.pizza.observations && (
                  <Text px={2} fontSize="14px">
                    {`obs: ${element.pizza.observations}`}
                  </Text>
                )}
                <Box px={2}>
                  {element.pizza.flavors.map((flavor, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Text key={index.toString()} fontSize="14px">
                      {`${flavor.quantity} ${
                        element.pizza.size.numberOfFlavors > 1
                          ? `1/${element.pizza.size.numberOfFlavors} ${flavor.name}`
                          : flavor.name
                      }`}
                    </Text>
                  ))}
                  <Text key={element.pizza.edge.name} fontSize="14px">
                    {`${element.pizza.edge.quantity} ${element.pizza.edge.name}`}
                  </Text>
                </Box>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.900">
                    {formatPrice(element.pizza.total)}
                  </Text>
                  {/* right */}
                </Flex>
              </Box>
            );

          default:
            return <div key={v4()}>Elemento do carrinho invalido</div>;
        }
      })}
      <Flex w="100%" justifyContent="space-between">
        <Text>Subtotal</Text>
        <Text>R$40,00</Text>
      </Flex>
      <Flex w="100%" justifyContent="space-between">
        <Text>Taxa de entrega</Text>
        <Text>R$8,00</Text>
      </Flex>
      <Flex w="100%" justifyContent="space-between">
        <Text>Total</Text>
        <Text>R$ 48,00</Text>
      </Flex>
    </Box>
  );
}

export { OrderPlaced };

/*
 *                   {/* <Flex maxW="100px" alignItems="center">
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleDecrementcart(
                          element.item.id,
                          element.item.quantity,
                          "DEFAULT"
                        )
                      }
                      size="sm"
                    >
                      {element.item.quantity > 1 ? "-" : <RiDeleteBinLine />}
                    </Button>
                    <Box p={2}> {element.item.quantity}</Box>
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleIncrementCart(element.item.id, "DEFAULT")
                      }
                      size="sm"
                    >
                      +
                    </Button>
                  </Flex> */

/* <Flex maxW="100px" alignItems="center">
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleDecrementcart(
                          element.pizza.id,
                          element.pizza.quantity,
                          "PIZZA"
                        )
                      }
                      size="sm"
                    >
                      {element.pizza.quantity > 1 ? "-" : <RiDeleteBinLine />}
                    </Button>
                    <Box p={2}> {element.pizza.quantity}</Box>
                    <Button
                      borderRadius="0"
                      colorScheme="primary"
                      variant="outline"
                      onClick={() =>
                        handleIncrementCart(element.pizza.id, "PIZZA")
                      }
                      size="sm"
                    >
                      +
                    </Button>
                  </Flex> */
