/* eslint-disable react/require-default-props */
import { Grid, GridItem, Text, Image } from "@chakra-ui/react";
import { formatPrice } from "../utils/formatPrice";

interface ItemCardProps {
  title: string;
  description?: string;
  price: number;
  urlImage?: string;
}

function ItemCard({
  description,
  title,
  price,
  urlImage,
}: ItemCardProps): JSX.Element {
  return (
    <Grid
      templateColumns="3fr 1fr"
      borderBottom="1px solid #d5d5d5"
      // className="ripple"
    >
      <GridItem p="1rem">
        <Text fontSize="md" fontWeight="base" color="gray.900">
          {title}
        </Text>
        {description && (
          <Text fontSize="sm" lineHeight="normal">
            {description}
          </Text>
        )}
        <Text>{formatPrice(price)}</Text>
      </GridItem>

      <GridItem p="1rem 1rem 1rem 0">
        {urlImage && (
          <Image
            src={urlImage}
            loading="lazy"
            borderRadius="0.5rem"
            boxSize="80px"
            objectFit="cover"
          />
        )}
      </GridItem>
    </Grid>
  );
}

export { ItemCard };
