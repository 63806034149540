import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { AlloawedRoute } from "./Components/AlloawedRoute";

import { InfoProvider } from "./contexts/info";

import { ShoppingCartPage } from "./Pages/ShoppingCartPage";
import { Menu } from "./Pages/Menu";
// import { Register } from "./pages/Register";
import { ItemDetails } from "./Pages/ItemDetails";
import { OrderDetails } from "./Pages/OrderDetails";
import { NotFound } from "./Pages/NotFound";
import { PizzaDetails } from "./Pages/PizzaDetails";
import { OrderPlaced } from "./Pages/OrderPlaced";

const theme = extendTheme({
  colors: {
    primary: {
      "50": "#FEEEE6",
      "100": "#FDD0B9",
      "200": "#FCB28C",
      "300": "#FB945F",
      "400": "#FA7532",
      "500": "#F95706",
      "600": "#C84604",
      "700": "#963403",
      "800": "#642302",
      "900": "#321101",
    },
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        color: "gray.600",
        // pull to refresh (disable)
        overscrollBehaviorY: "contain",
        // display: "flex",
        // justifyContent: "center",
      },
      // styles for the `a`
    },
  },
  components: {
    Button: {
      variants: {
        "with-shadow": {
          bg: "red.400",
          boxShadow: "0 0 2px 2px #efdfde",
        },
      },
    },
  },
});

function App(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subdomain, setSubdomain] = useState<string | null>(null);
  useEffect(() => {
    const { host } = window.location;
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) setSubdomain(arr[0]);
  }, []);
  console.log(subdomain);
  return (
    <ChakraProvider theme={theme}>
      <InfoProvider>
        {/* Inject head */}
        {/* <HelmetProvider> */}
        {/* <Helmet>
            <link
              rel="shortcut icon"
              href="logo128128mepedeai-01.png"
              type="image/png"
            />
            <title>Teste</title>
          </Helmet> */}
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<Entry />} /> */}
            {/* <Route path="/register" element={<Register />} /> */}
            {/* voltar com isso caso de problema no erro not found */}
            {/* <Route path="/menu" element={<Menu />} /> */}
            <Route path="/menu" element={<Menu />} />
            <Route path="/details/:productId" element={<ItemDetails />} />
            <Route
              path="/pizza-details/:pizzaId/:sizeId"
              element={<PizzaDetails />}
            />
            <Route path="/" element={<AlloawedRoute />}>
              <Route path="/shopping-cart" element={<ShoppingCartPage />} />
              <Route path="/order-details" element={<OrderDetails />} />
              <Route path="/order-placed" element={<OrderPlaced />} />
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        {/* </HelmetProvider> */}
      </InfoProvider>
    </ChakraProvider>
  );
}

export { App };
