import { Box, Text, useInterval } from "@chakra-ui/react";
import { useState } from "react";
import { isBefore, isAfter } from "date-fns";
import { useInfo } from "../contexts/info";

function getOpen(openingHours: any): boolean {
  let state = false;
  const dateNow = new Date();

  const year = dateNow.getFullYear();
  const month = dateNow.getMonth();
  const day = dateNow.getDate();
  const weekDay = dateNow.getDay();

  openingHours.forEach((hour: any) => {
    const [hi, mi] = hour.startAt.split(":");
    const [hf, mf] = hour.endAt.split(":");
    const startDate = new Date(year, month, day, Number(hi), Number(mi));
    const endDate = new Date(year, month, day, Number(hf), Number(mf));
    if (weekDay === 0) {
      if (
        hour.sunday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 1) {
      if (
        hour.monday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 2) {
      if (
        hour.tuesday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 3) {
      if (
        hour.wednesday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 4) {
      if (
        hour.thursday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 5) {
      if (
        hour.friday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
    if (weekDay === 6) {
      if (
        hour.saturday === 1 &&
        isAfter(dateNow, startDate) &&
        isBefore(dateNow, endDate)
      ) {
        state = true;
      }
    }
  });

  return state;
}

// a cada 30s verifica se a loja esta aberta
const INTERVAL = 30000;

function OpenningHour(): JSX.Element {
  const { info } = useInfo();
  const [isOpen, setIsOpen] = useState(() => getOpen(info.openingHours));
  // setar com o valor buscado pela funcao( reescrever)

  useInterval(() => setIsOpen(getOpen(info.openingHours)), INTERVAL);
  return (
    <Box>
      <Text
        color="white"
        bg={isOpen ? "green.500" : "red.500"}
        p="0 1rem 0 1rem"
        borderRadius="0.5rem"
      >
        {isOpen ? "Aberto" : "Fechado"}
      </Text>
    </Box>
  );
}

export { OpenningHour };
