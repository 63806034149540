import { useEffect } from "react";
import {
  Box,
  FormLabel,
  Textarea,
  Text,
  useToast,
  Flex,
} from "@chakra-ui/react";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import {
  Link as LinkRouterDom,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import { v4 } from "uuid";

import { MdOutlineArrowBack } from "react-icons/md";

import { formatPrice } from "../utils/formatPrice";
import { useCart } from "../store/useCart";
import { useFetch } from "../hooks/useFetch";

import { PizzaDetailFooter } from "../Components/PizzaDetailFooter";
import { ComplementPizzaContainer } from "../Components/ComplementPizzaContainer";
import { EdgePizzaContainer } from "../Components/EdgePizzaContainer";

export type TPrice = {
  id: string;
  sizeId: string;
  originalPrice: number;
  discountPrice: number;
};

export type TFlavor = {
  id: string;
  productId: string;
  name: string;
  description: string;
  urlImage: string;
  index: number;
  prices: TPrice[];
  originalPrice: number;
};

export type TSize = {
  id: string;
  name: string;
  pizzaId: string;
  productId: string;
  index: number;
  numberOfFlavors: number;
  prices: TPrice[];
};

export type TEdge = {
  id: string;
  name: string;
  pizzaId: string;
  index: number;
  price: number;
  productId: string;
};

export type TPizza = {
  id: string;
  flavors: TFlavor[];
  sizes: TSize[];
  edges: TEdge[];
};

export interface PizzaDetailsFormData {
  pizzaId: string;
  name: string;
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  totalOptions: number;
  observations: string;
  complements: {
    id: string;
    name: string;
    min: number;
    max: number;
    options: {
      id: string;
      name: string;
      cod: string;
      quantity: number;
      unitPrice: number;
      price: number; // price = quantity * unitPrice
    }[];
  }[];

  total: number;
}

export interface PizzaDetailsFormData2 {
  pizzaId: string;
  size: {
    id: string;
    productId: string;
    name: string;
    numberOfFlavors: number;
  };
  flavors: {
    id: string;
    name: string;
    productId: string;
    unitPrice: number;
    quantity: number;
    price: number;
  }[];
  edge: {
    id: string;
    productId: string;
    name: string;
    unitPrice: number;
    quantity: number;
    price: number;
  };
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  totalOptions: number;
  observations: string;
  total: number;
}

export function delay(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 500);
  });
}

export const getFlavorText = (numberOfFlavors: number): string =>
  numberOfFlavors > 1
    ? `${numberOfFlavors} sabores`
    : `${numberOfFlavors} sabor`;

function PizzaDetails(): JSX.Element {
  const { pizzaId, sizeId } = useParams();
  const addPizzaToCart = useCart((state) => state.addPizzaToCart);
  const toast = useToast();
  const navigate = useNavigate();
  const { data: pizza, error } = useFetch<TPizza>(
    `/pizzas/${pizzaId}/sizes/${sizeId}`
  );

  // informacoes dos item
  const { name, numberOfFlavors, minPrice, productId } = useLocation()
    .state as {
    name: string;
    productId: string;
    numberOfFlavors: number;
    minPrice: number;
  };

  const array = Array.from({ length: numberOfFlavors });

  const choiceFlavors = array.map((el, index) => ({
    indice: index,
    id: v4(),
    name: `Escolha um sabor`,
    flavors: pizza?.flavors,
  }));

  const metods = useForm<PizzaDetailsFormData2>({
    defaultValues: {
      pizzaId,
      size: {
        id: sizeId,
        productId,
        name,
        numberOfFlavors,
      },
      unitPrice: 0,
      quantity: 1,
      observations: "",
    },
  });

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {});
    Events.scrollEvent.register("end", () => {});

    scrollSpy.update();

    scroll.scrollToTop({ duration: 100 });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  async function handleFormSubmit(data: PizzaDetailsFormData2): Promise<void> {
    addPizzaToCart(data);
    toast({
      title: "Item adicionado á sua sacola",
      status: "success",
      position: "bottom",
      duration: 1000,
    });
    await delay();
    navigate("/shopping-cart", { replace: true });
  }
  if (error) {
    return <div>Ocorreu um erro!!</div>;
  }

  return (
    <FormProvider {...metods}>
      <Box
        as="form"
        onSubmit={metods.handleSubmit(handleFormSubmit)}
        maxW="780px"
      >
        {/* componente com foto, descricao, name e preco do produto */}
        <Box
          h="200px"
          w="100%"
          bg="white"
          position="relative"
          // background={`url(${urlImage}) no-repeat center`}
          backgroundSize="cover"
        >
          <Box
            position="absolute"
            top="60px"
            left="1rem"
            color="black"
            bg="white"
            borderRadius="50%"
          >
            <LinkRouterDom to="/menu">
              <MdOutlineArrowBack size={30} />
            </LinkRouterDom>
          </Box>
        </Box>
        <Box p="1rem">
          <Text // tirar esse input( nome setado defaut)
            fontSize="lg"
            fontWeight="base"
            color="gray.900"
            p={0}
            border="none"
          >{`${name} ${getFlavorText(numberOfFlavors)}`}</Text>
          <Text p={1}>{`A partir de ${formatPrice(minPrice)}`}</Text>
        </Box>

        {/* Se tiver complementos,sao listados aqui */}
        {!pizza ? (
          <Flex w="100%" justifyContent="center">
            <BeatLoader size={12} color="red" />
          </Flex>
        ) : (
          <Box>
            {/* sabores */}
            {choiceFlavors.map((el, flavorIndex) => (
              <Element
                // eslint-disable-next-line react/no-array-index-key
                key={`${el.id}-${flavorIndex}`}
                name={el.indice.toString()}
              >
                <ComplementPizzaContainer
                  key={el.name}
                  name={el.name}
                  flavorIndex={flavorIndex}
                  flavors={pizza.flavors}
                  numberOfFlavors={numberOfFlavors}
                />
              </Element>
            ))}
            {/* Bordas */}
            <Element name="edges">
              <EdgePizzaContainer
                name="Escolha sua borda"
                edges={pizza.edges}
              />
            </Element>
            {/* Card de observacoes( componentizar!!!!!) */}
            <Box m="1rem 0 1rem 0" p="1rem">
              <FormLabel>Observações:</FormLabel>
              <Textarea
                {...metods.register("observations")}
                placeholder="Digite aqui suas observações."
              />
            </Box>
            <div
              style={{ background: "white", height: "60px", width: "100%" }}
            />
            <PizzaDetailFooter control={metods.control} />
          </Box>
        )}

        {/* espaco em branco no pe da pagina(para o footer nao tampar) */}
      </Box>
      {/* <Button type="submit">enviar</Button> */}
    </FormProvider>
  );
}

export { PizzaDetails };
