import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerFooter,
  Button,
  Box,
  Flex,
  Text,
  Heading,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { BiStore } from "react-icons/bi";
import { BsTelephone } from "react-icons/bs";
import { MdOutlinePlace } from "react-icons/md";
import { useInfo } from "../contexts/info";

type Hours = {
  sunday: string;
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
};

function Info(): JSX.Element {
  const { isOpen, onClose, info } = useInfo();
  const teste = useMemo(() => {
    const hours: Hours = {
      sunday: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
    };
    info.openingHours.forEach((element) => {
      const [hi, mi] = element.startAt.split(":");
      const [hf, mf] = element.endAt.split(":");
      if (element.sunday === 1) {
        hours.sunday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.monday === 1) {
        hours.monday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.tuesday === 1) {
        hours.tuesday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.wednesday === 1) {
        hours.wednesday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.thursday === 1) {
        hours.thursday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.friday === 1) {
        hours.friday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
      if (element.saturday === 1) {
        hours.saturday += `${hi}:${mi} às ${hf}:${mf} - `;
      }
    });
    return hours;
  }, [info.openingHours]);

  return (
    <Drawer isOpen={isOpen} placement="right" size="full" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {/* <DrawerHeader>Informações sobre a loja</DrawerHeader> */}

        <DrawerBody>
          <Heading as="h6" size="sm" mt={4}>
            Informações sobre a loja
          </Heading>
          <Box fontSize="sm">
            <Flex alignItems="center">
              <BiStore />
              <Text ml="0.5rem"> {info.tradeName}</Text>
            </Flex>
            <Flex alignItems="center">
              <BsTelephone />
              <Text ml="0.5rem">{info.phone.phoneNumber}</Text>
            </Flex>
            <Flex alignItems="center">
              <MdOutlinePlace />
              <Text ml="0.5rem">{`${info.address.street}, ${info.address.number} - ${info.address.neighborhood}`}</Text>
            </Flex>
            <Flex ml={4}>
              <Text ml="0.5rem">{`${info.address.city} - ${info.address.state}`}</Text>
            </Flex>
            <Flex ml={4}>
              <Text ml="0.5rem">{`Cep: ${info.address.zipcode}`}</Text>
            </Flex>
            <Flex mt={2}>
              <Text>CNPJ</Text>
              <Text>{`: ${info.registrationNumber}`}</Text>
            </Flex>
          </Box>
          <Heading as="h6" size="sm" mt={4}>
            Horário de funcionamento
          </Heading>

          <Table variant="unstyled" size="sm">
            <Tbody>
              <Tr>
                <Td px={0}>Domingo</Td>
                <Td>{teste.sunday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Segunda-Feira</Td>
                <Td>{teste.monday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Terça-Feira</Td>
                <Td>{teste.tuesday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Quarta-Feira</Td>
                <Td>{teste.wednesday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Quinta-Feira</Td>
                <Td>{teste.thursday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Sexta-Feira</Td>
                <Td>{teste.friday}</Td>
              </Tr>
              <Tr>
                <Td px={0}>Sábado</Td>
                <Td>{teste.saturday}</Td>
              </Tr>
            </Tbody>
          </Table>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue">Save</Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

export { Info };
