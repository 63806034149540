import { Flex } from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";

interface IsRequiredProps {
  checked: boolean;
  isInvalid: boolean;
}

function IsRequired({ checked, isInvalid }: IsRequiredProps): JSX.Element {
  if (checked) {
    return <FiCheckCircle size={20} color="green" />;
  }

  return (
    <Flex
      color={isInvalid ? "red" : "white"}
      bg={isInvalid ? "white" : "gray.600"}
      border={isInvalid ? "1px solid red" : "1px solid gray.600"}
      borderRadius="md"
      fontSize="13px"
      px={1}
    >
      Obrigatório
    </Flex>
  );
}

export { IsRequired };
