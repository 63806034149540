import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

function NotFound(): JSX.Element {
  return (
    <Box
      h="100vh"
      w="100%"
      bg="green.300"
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Heading mr="1rem">404</Heading>
      <Heading>Error</Heading>
      <Text fontSize="2xl">Página não encontrada!!</Text>
      <Link to="/menu">
        <Flex p={4} mt={4} border="2px solid #fff" borderRadius="md">
          Tentar novamente
        </Flex>
      </Link>
    </Box>
  );
}

export { NotFound };
