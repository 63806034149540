import { useState } from "react";
import { Box, Button, Flex, FormLabel, Input, Text } from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { AiOutlineCheckCircle } from "react-icons/ai";

import { useEffect } from "react";
import { ItemDetailsFormData2 } from "../Pages/ItemDetails";

import { TComplement } from "../types";

import { formatPrice } from "../utils/formatPrice";

interface ComplementCardProps {
  complement: TComplement;
  complementIndex: number;
}

function ComplementItemContainer({
  complement,
  complementIndex,
}: ComplementCardProps): JSX.Element {
  const { max, min, name, options } = complement;

  const [count, setCount] = useState(0);
  const { register, control, getValues, setValue } =
    useFormContext<ItemDetailsFormData2>();

  // sem useEffect => Warning: Cannot update a component (`ItemDetailFooter`) while rendering a different component (`ComplementItemContainer`). To locate the bad setState() call
  useEffect(() => {
    setValue(`complements.${complementIndex}.min`, min);
  }, [complementIndex, min, setValue]);

  const handleIncrement = (optionIndex: number): void => {
    const oldValue = getValues(
      `complements.${complementIndex}.options.${optionIndex}.quantity`
    );
    setValue(
      `complements.${complementIndex}.options.${optionIndex}.quantity`,
      oldValue + 1
    );
    setCount((state) => state + 1);
  };

  const handleDecrease = (optionIndex: number): void => {
    const oldValue = getValues(
      `complements.${complementIndex}.options.${optionIndex}.quantity`
    );
    if (oldValue > 0) {
      setValue(
        `complements.${complementIndex}.options.${optionIndex}.quantity`,
        oldValue - 1
      );
      setCount((state) => state - 1);
    }
  };

  function optionQuantityIsNull(optionIndex: number): boolean {
    const quantity = getValues(
      `complements.${complementIndex}.options.${optionIndex}.quantity`
    );

    return quantity === 0 || quantity === undefined;
  }

  return (
    <>
      <Flex
        p="1rem"
        borderBottom="1px solid #d5d5d5"
        bg="#f3f5f7"
        position="sticky"
        zIndex="sticky"
        top="0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box w="80%">
          <Input
            {...register(`complements.${complementIndex}.name`)}
            defaultValue={name}
            fontWeight="bold"
            fontSize="16px"
            readOnly
            p={0}
            border="none"
          />
          <Text>
            {max > 1 ? `Escolha até ${max} opções` : `Escolha ${max} opção`}
          </Text>
        </Box>
        {min > 0 &&
          (count === max ? (
            <AiOutlineCheckCircle size={30} color="green" />
          ) : (
            <Box
              fontSize="13px"
              bg="gray.600"
              color="white"
              borderRadius="md"
              px={1}
            >
              Obrigatório
            </Box>
          ))}
      </Flex>

      {options.map((option, optionIndex) => (
        <FormLabel
          htmlFor={option.id}
          cursor="pointer"
          key={option.id}
          display="flex"
          flexDirection="row"
          p="1rem"
          alignItems="center "
          justifyContent="space-between"
          bg="white"
          borderBottom="1px solid #d5d5d5"
        >
          <Box w="80%">
            <Input
              type="hidden"
              {...register(
                `complements.${complementIndex}.options.${optionIndex}.productId`
              )}
              defaultValue={option.productId}
            />
            <Input
              {...register(
                `complements.${complementIndex}.options.${optionIndex}.name`
              )}
              defaultValue={option.name}
              readOnly
              border="none"
              p={0}
            />
            <Text> + {formatPrice(option.originalPrice)}</Text>
            <Input
              {...register(
                `complements.${complementIndex}.options.${optionIndex}.unitPrice`,
                { valueAsNumber: true }
              )}
              defaultValue={option.originalPrice}
              readOnly
              border="none"
              p={0}
              type="hidden"
            />
          </Box>

          <Flex maxW="100px">
            <Button
              borderRadius="0"
              colorScheme="primary"
              variant="outline"
              onClick={() => {
                handleDecrease(optionIndex);
              }}
              size="sm"
              visibility={
                optionQuantityIsNull(optionIndex) ? "hidden" : "visible"
              }
              disabled={optionQuantityIsNull(optionIndex)}
            >
              -
            </Button>

            <Controller
              name={
                `complements.${complementIndex}.options.${optionIndex}.quantity` as const
              }
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Input
                  type="number"
                  size="sm"
                  {...field}
                  textAlign="center"
                  readOnly
                  border="none"
                  p={0}
                  visibility={
                    optionQuantityIsNull(optionIndex) ? "hidden" : "visible"
                  }
                  borderRadius="none"
                  onChange={(e) => {
                    field.onChange(parseInt(e.target.value, 10));
                  }}
                />
              )}
            />
            <Button
              id={option.id}
              borderRadius="0"
              colorScheme="primary"
              variant="outline"
              onClick={() => handleIncrement(optionIndex)}
              size="sm"
              disabled={count >= max}
            >
              +
            </Button>
          </Flex>
        </FormLabel>
      ))}
    </>
  );
}

export { ComplementItemContainer };
