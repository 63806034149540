// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { create } from "zustand";
import { v4 } from "uuid";
import { persist } from "zustand/middleware";
// import { Address } from "../Components/Address";

type Address = {
  id: string;
  street: string;
  neighborhood: string;
  city: string;
  uf: string;
  zipCode: string;
  number: string;
  complement?: string;
  reference?: string;
  selected: boolean;
};
// type User = {
//   name: string;
//   email: string;
//   phoneNumber: string;
//   paymentPreference: string;
//   documentNumber: string;
//   adresses: Address[];
// };

interface UserState {
  name: string;
  email: string;
  phoneNumber: string;
  paymentPreference: string;
  documentNumber: string;
  adresses: Address[];
  addAddress: (address: Omit<Address, "id">) => void;
  removeAddress: (id: string) => void;
  selectAddress: (id: string) => void;
  setPaymentPreference: (method: string) => void;
  setName: (name: string) => void;
}

export const useUser = create<UserState>()(
  persist(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (set, get) => ({
      name: "",
      email: "",
      phoneNumber: "",
      paymentPreference: "",
      documentNumber: "",
      adresses: [] as Address[],

      addAddress: (newAddress) =>
        set((state) => ({
          adresses: [
            ...state.adresses.map((item) => ({
              ...item,
              selected: false,
            })),
            { ...newAddress, id: v4() },
          ],
        })),
      removeAddress: (id) =>
        set((state) => ({
          adresses: state.adresses.filter((address) => address.id !== id),
        })),

      selectAddress: (id) =>
        set((state) => ({
          adresses: state.adresses.map((address) => {
            if (address.id === id) {
              return {
                ...address,
                selected: true,
              };
            }
            return {
              ...address,
              selected: false,
            };
          }),
        })),

      setPaymentPreference: (method: string) =>
        set(() => ({ paymentPreference: method })),

      setName: (name) => set(() => ({ name })),
    }),
    {
      name: "@projeto-pizzaria:data",
      onRehydrateStorage: () => {
        console.log("User: hydration starts");

        // optional
        return (stat, error) => {
          if (error) {
            console.log("an error happened during hydration of user", error);
          } else {
            console.log("User: hydration finished");
          }
        };
      },
    }
  )
);

// ^4.1.1
