import { useState } from "react";
import { Flex, Box, Button, Input } from "@chakra-ui/react";
import BeatLoader from "react-spinners/BeatLoader";

import { Control, useFormContext, useWatch } from "react-hook-form";

import { ItemDetailsFormData2 } from "../Pages/ItemDetails";
import { formatPrice } from "../utils/formatPrice";

function ItemDetailFooter({
  control,
}: {
  control: Control<ItemDetailsFormData2>;
}): JSX.Element {
  const {
    register,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const unitPrice = (getValues("unitPrice") as number) || 0;
  const [quantity, setQuantity] = useState<number>(getValues("quantity") || 1);

  let total = 0;
  total += unitPrice;

  const complements = useWatch({
    control,
    name: "complements",
  });

  const checked = complements?.every((complement) => {
    const quantitySum = complement.options.reduce(
      (sum, option) => sum + option.quantity,
      0
    );

    return quantitySum >= complement.min;
  });

  const totalOptions = complements?.reduce(
    (sum, complement) =>
      sum +
      complement.options.reduce(
        (sum2, option) =>
          sum2 + (option.quantity || 0) * (option.unitPrice || 0),
        0
      ),
    0
  );

  total += totalOptions || 0;

  total *= quantity;

  // setar price
  setValue("price", unitPrice * quantity);
  setValue("totalOptions", totalOptions || 0);
  setValue("total", total);

  const handleIncrement = (): void => {
    const oldValue = getValues("quantity");
    setValue("quantity", oldValue + 1);
    setQuantity((state) => state + 1);
  };

  const handleDecrease = (): void => {
    const oldValue = getValues("quantity");
    if (oldValue > 1) {
      setValue("quantity", oldValue - 1);
      setQuantity((state) => state - 1);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "60px",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 3000,
        borderTop: "1px solid #d5d5d5",
        background: "#ffff",
      }}
    >
      <Flex maxW="100px" mr={4}>
        <Button
          borderRadius="0"
          colorScheme="primary"
          variant="outline"
          onClick={() => handleDecrease()}
          size="sm"
        >
          -
        </Button>

        {/* <Controller
          name="quantity"
          control={control}
          defaultValue={1}
          render={({ field }) => ( */}
        <Input
          {...register("quantity")}
          type="number"
          size="sm"
          // {...field}
          textAlign="center"
          readOnly
          border="none"
          borderRadius="none"
          onChange={(e) => {
            parseInt(e.target.value, 10);
          }}
        />
        {/* )}
        /> */}
        <Button
          borderRadius="0"
          colorScheme="primary"
          // color="#F56565"
          variant="outline"
          onClick={() => handleIncrement()}
          size="sm"
          disabled={!checked}
        >
          +
        </Button>
      </Flex>
      <Button
        type="submit"
        // colorScheme="#fb864b"
        colorScheme="primary"
        width="60%"
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-between"
        isLoading={isSubmitting}
        spinner={<BeatLoader size={8} color="white" />}
        disabled={!checked}
      >
        Adicionar
        <Box>{formatPrice(total)}</Box>
      </Button>
    </div>
  );
}

export { ItemDetailFooter };
