import { Navigate, Outlet } from "react-router-dom";
import { useCart } from "../store/useCart";

function AlloawedRoute(): JSX.Element {
  const { quantityOftemsInCart } = useCart();

  // permite o acesso a rota, somente setiverintensno carrinho
  if (quantityOftemsInCart() === 0) {
    return <Navigate to="/menu" replace />;
  }
  return <Outlet />;
}

export { AlloawedRoute };
