/* eslint-disable no-nested-ternary */
import { useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  Text,
  Center,
  InputGroup,
  InputLeftElement,
  Checkbox,
  Heading,
  useToast,
  Icon,
  FormErrorMessage,
} from "@chakra-ui/react";

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AxiosError } from "axios";

import { CgChevronLeft, CgChevronDown } from "react-icons/cg";
import { MdOutlinePlace } from "react-icons/md";

import { api } from "../services/api";
import { useCart } from "../store/useCart";
import { useUser } from "../store/useUser";
import { currencyMask } from "../utils/currencyMask";
import { phoneNumberMask } from "../utils/phoneNumberMask";
import { useInfo } from "../contexts/info";
import { formatPrice } from "../utils/formatPrice";
import { formatStringInputToNumber } from "../utils/formatStringInputToNumber";

import { Address } from "../Components/Address";
import { IsRequired } from "../Components/IsRequired";
import { Payment } from "../Components/Payment";
import { PaymentCard } from "../Components/PaymentCard";

type OrderType = "DELIVERY" | "PICKED_UP";
export type PaymentType =
  | "CASH"
  | "DEBIT_CARD"
  | "CREDIT_CARD"
  | "PIX"
  | "MEAL_TICKET"
  | undefined;

interface FormData {
  orderType: OrderType;
  additionalInfo: string;

  /// ////////// variaveis temporarias
  isDocument: boolean;
  isAddress: boolean;
  /// //////////////////////////////
  payment: {
    id: string;
    name: string;
    method: PaymentType;
    type: string;
    brand: string;
  };
  change?: string; // troco

  custumer: {
    name: string;
    documentNumber: string; // se precisar de cpf na nota
    phoneNumber: string;
  };
  delivery: {
    deliveryInformation: string;
    deliveryFee: number;
    address: {
      street: string;
      neighborhood: string;
      number: string;
      city: string;
      uf: string;
      zipCode: string;
      complement?: string;
      reference?: string;
    };
  };

  subTotal: number;
  deliveryFee: number;
  total: number;
}

function ShowAddress({
  orderType,
  errorAddress,
}: // errorAddress,
{
  orderType: OrderType;
  errorAddress: any;
}): JSX.Element {
  const { info } = useInfo();
  const adresses = useUser((state) => state.adresses);
  const selectedAddress = adresses.find((address) => address.selected === true);

  switch (orderType) {
    case "DELIVERY":
      return (
        <Box>
          <FormLabel>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap={2}>
                <Text fontWeight="bold">Entregar em</Text>
                <CgChevronDown size={20} />
              </Flex>
              <IsRequired
                checked={!!selectedAddress}
                isInvalid={!!errorAddress}
              />
            </Flex>
          </FormLabel>
          <Address />
        </Box>
      );
    case "PICKED_UP":
      return (
        <Box fontSize="16px">
          <Flex alignItems="center" gap={2}>
            <Text fontWeight="bold">Retirar em</Text>
            <CgChevronDown size={20} />
          </Flex>
          <Flex alignItems="center" gap={2}>
            <MdOutlinePlace />
            {`${info.address.street}, ${info.address.number} - ${info.address.complement} ${info.address.neighborhood}`}
          </Flex>
        </Box>
      );
    default:
      return <div />;
  }
}

/// //////////////////////////////////////////////////////////////////////

const schema = Yup.object().shape({
  orderType: Yup.string().required(),

  isAddress: Yup.boolean().when("orderType", {
    is: "DELIVERY",
    then: (sch) => sch.required().oneOf([true]),
  }),
  custumer: Yup.object().shape({
    name: Yup.string().required(),
    phoneNumber: Yup.string().length(15),
  }),

  payment: Yup.object().shape({
    id: Yup.string().required(),
    // phoneNumber: Yup.string().length(15),
  }),
});

function OrderDetails(): JSX.Element {
  const adresses = useUser((state) => state.adresses);
  const selectedAddress = adresses.find((address) => address.selected === true);
  const { info } = useInfo();

  const name = useUser((state) => state.name);
  const setName = useUser((state) => state.setName);

  const { cart } = useCart();
  const totalCart = useCart((state) => state.totalCart);
  const emptyCart = useCart((state) => state.emptyCart);

  const subTotal = useMemo(() => totalCart(), [totalCart]);

  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenModalChange,
    onOpen: onOpenModalChange,
    onClose: onCloseModalChange,
  } = useDisclosure();

  const {
    register,
    unregister,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    setError,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      isDocument: false,
      custumer: {
        name,
      },
      delivery: {
        deliveryInformation: "",
      },
    },
    resolver: yupResolver(schema),
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const fee = 0.0;

  useEffect(() => {
    setValue("isAddress", !!selectedAddress);
    setValue("subTotal", subTotal);
    resetField("change");
  }, [resetField, selectedAddress, setValue, subTotal]);

  // Watchs
  // o metodo de validar , foi mudado p onChange, verificar se precisa dos watch
  const isDocument = watch("isDocument");
  const watchOrderType = watch("orderType");
  const watchName = watch("custumer.name");
  const watchNumberPhone = watch("custumer.phoneNumber");
  const selectedId = watch("payment.id");

  function handlePhonrNumberMask(e: any): any {
    let { value } = e.currentTarget;
    value = phoneNumberMask(value);
    e.currentTarget.value = value;
    return value;
  }

  // Pagamentos na entrega
  const paymentMethodsOffline = useMemo(
    () => info.payments.filter((payment) => payment.type === "OFFLINE"),
    [info.payments]
  );

  async function handleFormSubmit(data: FormData): Promise<void> {
    // window.fbq("track", "Purchase", { value: 0.0, currency: "USD" });
    const intemsPizza = cart
      .filter((element) => element.type === "PIZZA")
      .map((ele) => {
        const flavors = ele.pizza.flavors.map((flavor) => ({
          id: flavor.id,
          productId: flavor.productId,
          name: flavor.name,
          quantity: flavor.quantity,
          unitPrice: flavor.unitPrice,
          price: flavor.price,
        }));

        const edge = {
          id: ele.pizza.edge.id,
          productId: ele.pizza.edge.productId,
          name: ele.pizza.edge.name,
          quantity: ele.pizza.edge.quantity,
          unitPrice: ele.pizza.edge.unitPrice,
          price: ele.pizza.edge.price,
        };

        return {
          id: ele.pizza.id,
          name: ele.pizza.size.name,
          productId: ele.pizza.size.productId,
          quantity: ele.pizza.quantity,
          unitPrice: ele.pizza.unitPrice,
          price: ele.pizza.price,
          observations: ele.pizza.observations,
          totalOptions: ele.pizza.totalOptions,
          total: ele.pizza.total,
          options: [...flavors, edge],
        };
      });

    try {
      await api.post(`/companies/${process.env.REACT_APP_COMPANYID}/orders`, {
        orderType: data.orderType,
        paymentId: data.payment.id,
        additionalInfo: "",
        customer: data.custumer,
        itens: [
          ...cart
            .filter((element) => element.type === "DEFAULT")
            .reduce((acc, element) => [...acc, { ...element.item }], [] as any),
          ...intemsPizza,
        ],
        change: data.change
          ? formatStringInputToNumber(data.change)
          : undefined,

        delivery:
          watchOrderType === "DELIVERY"
            ? {
                deliveryInformation: data.delivery.deliveryInformation,

                address: selectedAddress,
              }
            : undefined,

        subTotal,
        deliveryFee: fee,
        total: subTotal + fee,
      });

      toast({
        title: "Seu Pedido foi encaminhado para loja",
        description:
          "Você será atualizado sobre o status do pedido via whatsapp",
        status: "success",
        position: "top",
        duration: 2000,
      });
      // esvaziar o carrinho
      // emptyCart();
      const teste = {
        name: "andrwe",
      };
      navigate("/order-placed", { replace: true, state: teste });
    } catch (error) {
      console.log(error);
      if (error instanceof AxiosError) {
        toast({
          title: "Oh não, a loja já está fechada",
          description: "Fique a vontante para ver nosso cardápio",
          status: "error",
          position: "top",
          duration: 2000,
        });
      } else {
        toast({
          title: "Algo deu errado!",
          description: "Tente novamente",
          status: "error",
          position: "top",
          duration: 2000,
        });
      }
    }
  }

  return (
    <Box p={4} as="form" onSubmit={handleSubmit(handleFormSubmit)}>
      {/* Header */}
      <Flex
        alignItems=" center"
        // justifyContent="space-between"
        mb={8}
        h="4rem"
        position="sticky"
        top="0"
        zIndex="sticky"
        bg="white"
      >
        <Link to="/shopping-cart">
          <Icon as={CgChevronLeft} h={6} w={6} color="primary.500" />
        </Link>
        <Heading as="h3" size="md" ml="95px">
          <Center>Informações</Center>
        </Heading>
      </Flex>

      {/* body: delivery,payment, nome, tefefone, cpf */}
      <Stack spacing={6} mt={4}>
        <FormControl>
          <FormLabel>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Text fontWeight="bold">Escolha uma opçao</Text>
              <IsRequired
                checked={!!watchOrderType}
                isInvalid={!!errors.orderType}
              />
            </Flex>
          </FormLabel>
          <Controller
            name="orderType"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <Stack spacing={4}>
                  <Radio value="DELIVERY" size="lg" colorScheme="green">
                    Entrega
                  </Radio>
                  <Radio value="PICKED_UP" size="lg" colorScheme="green">
                    Retirada no local
                  </Radio>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <ShowAddress
          orderType={watchOrderType}
          errorAddress={errors.isAddress}
        />

        {watchOrderType === "DELIVERY" && (
          <FormControl>
            <FormLabel>Informacoes extras</FormLabel>
            <Input
              {...register("delivery.deliveryInformation")}
              placeholder="Ex: Interfone quebrado"
            />
          </FormControl>
        )}

        <FormControl onClick={onOpen}>
          <FormLabel>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Text fontWeight="bold">Pagamento</Text>
              <IsRequired
                checked={!!selectedId}
                isInvalid={!!errors?.payment?.id}
              />
            </Flex>
          </FormLabel>
          <Payment
            paymentMethod={getValues("payment.method")}
            change={getValues("change")}
          />
        </FormControl>

        <FormControl>
          <FormLabel>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Text>Nome</Text>
              <IsRequired
                checked={!!watchName}
                isInvalid={!!errors.custumer?.name}
              />
            </Flex>
          </FormLabel>
          <Input
            {...register("custumer.name")}
            onBlur={(e) => setName(e.currentTarget.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>
            <Flex w="100%" alignItems="center" justifyContent="space-between">
              <Text>Telefone</Text>
              <IsRequired
                checked={watchNumberPhone?.length === 15}
                isInvalid={!!errors.custumer?.phoneNumber}
              />
            </Flex>
          </FormLabel>
          <Controller
            render={({ field }) => (
              <Input
                {...field}
                placeholder="(XX)XXXXX-XXXX"
                type="tel"
                onChange={(e) => field.onChange(handlePhonrNumberMask(e))}
              />
            )}
            name="custumer.phoneNumber"
            control={control}
            defaultValue=""
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="checkBox">
            <Flex justifyContent="space-between">
              <Flex alignItems="center" gap={1}>
                CPF na nota?
                <CgChevronDown size={18} />
              </Flex>
              <Checkbox
                id="checkBox"
                colorScheme="green"
                isChecked={isDocument}
                onChange={(e) => {
                  setValue("isDocument", !getValues("isDocument"));
                  e.currentTarget.checked = !e.currentTarget.checked;
                  return e;
                }}
              />
            </Flex>
          </FormLabel>
          {isDocument && <Input {...register("custumer.documentNumber")} />}
        </FormControl>

        {/* espaco */}
        <Flex height="70px" />
      </Stack>

      {/* Footer: botao fazer pedido */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "1rem 1rem",
          width: "100%",
          height: "60px",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 100,
          borderTop: "1px solid #d5d5d5",
          background: "#ffff",
        }}
      >
        <Button
          type="submit"
          colorScheme="primary"
          isFullWidth
          height="3rem"
          borderRadius="lg"
          // onClick={() => navigate("/shopping-cart")}
          isLoading={isSubmitting}
        >
          Fazer pedido
        </Button>
      </div>

      {/* Modais e drawlers */}
      {/* Payment choices */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Flex gap={4}>
              <CgChevronLeft size={30} color="green" onClick={onClose} />
              <Text>Formas de Pagamento</Text>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <Tabs>
              <TabList>
                <Tab>Pagar na Entrega</Tab>
                <Tab>Pagar pelo App</Tab>
              </TabList>

              <TabPanels>
                <TabPanel p="1rem 0 0 0">
                  <FormControl>
                    <Controller
                      name="payment.id"
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="column" spacing={2}>
                            {paymentMethodsOffline
                              .filter((payment) => payment.method === "CASH")
                              .map((payment) => (
                                <FormLabel
                                  key={payment.id}
                                  htmlFor={payment.id}
                                >
                                  <PaymentCard
                                    id={payment.id}
                                    name={payment.name}
                                    brand={payment.brand}
                                    isSelected={selectedId === payment.id}
                                    value={payment.id}
                                    onChange={() => {
                                      setValue("payment.name", payment.name);
                                      setValue(
                                        "payment.method",
                                        payment.method
                                      );
                                      onClose();
                                      onOpenModalChange();
                                    }}
                                  />
                                </FormLabel>
                              ))}
                          </Stack>
                          <Stack direction="column" spacing={2}>
                            {paymentMethodsOffline
                              .filter((payment) => payment.method === "PIX")
                              .map((payment) => (
                                <FormLabel
                                  key={payment.id}
                                  htmlFor={payment.id}
                                >
                                  <PaymentCard
                                    id={payment.id}
                                    name={payment.name}
                                    brand={payment.brand}
                                    isSelected={selectedId === payment.id}
                                    value={payment.id}
                                    onChange={() => {
                                      setValue("payment.name", payment.name);
                                      setValue(
                                        "payment.method",
                                        payment.method
                                      );
                                      onClose();
                                    }}
                                  />
                                </FormLabel>
                              ))}
                          </Stack>
                          <Text m="1rem 0 1rem 0">Crédito</Text>
                          <Stack direction="column" spacing={2}>
                            {paymentMethodsOffline
                              .filter(
                                (payment) => payment.method === "CREDIT_CARD"
                              )
                              .map((payment) => (
                                <FormLabel
                                  key={payment.id}
                                  htmlFor={payment.id}
                                >
                                  <PaymentCard
                                    id={payment.id}
                                    name={payment.name}
                                    brand={payment.brand}
                                    isSelected={selectedId === payment.id}
                                    value={payment.id}
                                    onChange={() => {
                                      setValue("payment.name", payment.name);
                                      setValue(
                                        "payment.method",
                                        payment.method
                                      );
                                      onClose();
                                    }}
                                  />
                                </FormLabel>
                              ))}
                          </Stack>
                          <Text m="1rem 0 1rem 0">Débito</Text>
                          <Stack direction="column">
                            {paymentMethodsOffline
                              .filter(
                                (payment) => payment.method === "DEBIT_CARD"
                              )
                              .map((payment) => (
                                <FormLabel
                                  key={payment.id}
                                  htmlFor={payment.id}
                                >
                                  <PaymentCard
                                    id={payment.id}
                                    name={payment.name}
                                    brand={payment.brand}
                                    isSelected={selectedId === payment.id}
                                    value={payment.id}
                                    onChange={() => {
                                      setValue("payment.name", payment.name);
                                      setValue(
                                        "payment.method",
                                        payment.method
                                      );
                                      onClose();
                                    }}
                                  />
                                </FormLabel>
                              ))}
                          </Stack>

                          <Text m="1rem 0 1rem 0">Vale Refeição</Text>
                          <Stack direction="column">
                            {paymentMethodsOffline
                              .filter(
                                (payment) => payment.method === "MEAL_TICKET"
                              )
                              .map((payment) => (
                                <FormLabel
                                  key={payment.id}
                                  htmlFor={payment.id}
                                >
                                  <PaymentCard
                                    id={payment.id}
                                    name={payment.name}
                                    brand={payment.brand}
                                    isSelected={selectedId === payment.id}
                                    value={payment.id}
                                    onChange={() => {
                                      setValue("payment.name", payment.name);
                                      setValue(
                                        "payment.method",
                                        payment.method
                                      );
                                      onClose();
                                    }}
                                  />
                                </FormLabel>
                              ))}
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                </TabPanel>
                <TabPanel>
                  <p>Não disponível no momento</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {/* Troco */}
      <Drawer
        isOpen={isOpenModalChange}
        placement="bottom"
        onClose={onCloseModalChange}
        closeOnOverlayClick={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <Box>
              <Text> Valor do pedido: {formatPrice(subTotal)}</Text>
              <Text>Quanto de troco?</Text>
            </Box>
          </DrawerHeader>
          <DrawerBody>
            <FormControl isInvalid={!!errors?.change}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  color="gray.300"
                  fontSize="1.2em"
                >
                  R$
                </InputLeftElement>
                <Input
                  {...register("change" /* , { valueAsNumber: true } */)}
                  placeholder="0,00"
                  type="tel"
                  onChange={(e) => {
                    let { value } = e.currentTarget;
                    value = currencyMask(value);
                    e.currentTarget.value = value;
                    if (formatStringInputToNumber(value) < subTotal) {
                      setError("change", {
                        type: "custom",
                        message: "Valor deve ser superior ao valor do pedido",
                      });
                    } else {
                      clearErrors("change");
                    }
                    return value;
                  }}
                />
              </InputGroup>
              <FormErrorMessage>{errors?.change?.message}</FormErrorMessage>
            </FormControl>
            <Center mt={4} mb={8}>
              <Button
                colorScheme="primary"
                variant="outline"
                mr={3}
                onClick={() => {
                  unregister("change");
                  onCloseModalChange();
                }}
                isFullWidth
              >
                Sem troco
              </Button>
              <Button
                colorScheme="primary"
                isFullWidth
                isDisabled={!!errors?.change}
                onClick={() => {
                  onCloseModalChange();
                }}
              >
                Confirmar
              </Button>
            </Center>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export { OrderDetails };
