import { useState } from "react";
import { scroller } from "react-scroll";
import {
  Box,
  Flex,
  Text,
  Image,
  Radio,
  RadioGroup,
  Stack,
  FormLabel,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";

import { AiOutlineCheckCircle } from "react-icons/ai";

import { formatPrice } from "../utils/formatPrice";
import { PizzaDetailsFormData2, TFlavor } from "../Pages/PizzaDetails";

interface ComplementCardProps {
  // complement: any;
  name: string;
  flavorIndex: number;
  flavors: TFlavor[];
  numberOfFlavors: number;
}

const getNumberOfFlavorsToText: Record<string, string> = {
  "0": "Escolha o primeiro sabor",
  "1": "Escolha o segundo sabor",
  "2": "Escolha o terceiro sabor",
  "3": "Escolha o quarto sabor",
};

function ComplementPizzaContainer({
  // complement,
  flavorIndex,

  flavors,
  numberOfFlavors,
}: ComplementCardProps): JSX.Element {
  const [isSelected, setIsSelected] = useState(false);
  const { control, setValue } = useFormContext<PizzaDetailsFormData2>();

  function scrollTo(): void {
    const next =
      flavorIndex === numberOfFlavors - 1 ? "edges" : flavorIndex + 1;

    scroller.scrollTo(`${next}`, {
      duration: 800,
      smooth: true,
    });
  }

  return (
    <>
      <Flex
        p="1rem"
        borderBottom="1px solid #d5d5d5"
        bg="#f3f5f7"
        position="sticky"
        zIndex="sticky"
        top="0"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Text fontWeight="bold" fontSize="18px" py={4}>
            {getNumberOfFlavorsToText[flavorIndex]}
          </Text>
        </Box>
        {isSelected ? (
          <AiOutlineCheckCircle size={30} color="green" />
        ) : (
          <Box
            fontSize="13px"
            bg="gray.600"
            color="white"
            borderRadius="md"
            px={1}
          >
            Obrigatório
          </Box>
        )}
      </Flex>
      {flavors.map((flavor) => (
        <Controller
          key={flavor.id}
          name={`flavors.${flavorIndex}.id`}
          control={control}
          render={({ field }) => (
            <RadioGroup {...field}>
              <Stack direction="column">
                <FormLabel
                  // necessario concatenar, cada bloco possui ids iguais
                  // htmlfor nao funciona
                  htmlFor={`${flavor.id}-${flavorIndex}`}
                  display="flex"
                  flexDirection="row"
                  p="1rem"
                  alignItems="center"
                  justifyContent="space-between"
                  bg="white"
                  borderBottom="1px solid #d5d5d5"
                >
                  <Box>
                    <Text fontWeight="bold">
                      {numberOfFlavors > 1
                        ? `1/${numberOfFlavors} ${flavor.name}`
                        : flavor.name}
                    </Text>
                    <Text>{flavor.description}</Text>
                    <Text>
                      {`+ ${formatPrice(
                        flavor.originalPrice / numberOfFlavors
                      )}`}
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {flavor.urlImage && (
                      <Image
                        src={flavor.urlImage}
                        loading="lazy"
                        borderRadius="0.5rem"
                        boxSize="50px"
                        objectFit="cover"
                        mr={1}
                      />
                    )}
                    <Radio
                      id={`${flavor.id}-${flavorIndex}`}
                      colorScheme="primary"
                      size="lg"
                      value={flavor.id}
                      onChange={() => {
                        setValue(`flavors.${flavorIndex}.name`, flavor.name);
                        setValue(
                          `flavors.${flavorIndex}.productId`,
                          flavor.productId
                        );
                        setValue(
                          `flavors.${flavorIndex}.unitPrice`,
                          flavor.originalPrice / numberOfFlavors
                        );
                        setValue(`flavors.${flavorIndex}.quantity`, 1);
                        setValue(
                          `flavors.${flavorIndex}.price`,
                          1 * (flavor.originalPrice / numberOfFlavors)
                        );
                        setIsSelected(true);
                        scrollTo();
                      }}
                    />
                  </Box>
                </FormLabel>
              </Stack>
            </RadioGroup>
          )}
        />
      ))}
    </>
  );
}

export { ComplementPizzaContainer };

/**
   const handleIncrement = (optionIndex: number): void => {
    const oldValue = getValues(
      `flavors.${flavorIndex}.options.${optionIndex}.quantity`
    );
    setValue(
      `complements.${complementIndex}.options.${optionIndex}.quantity`,
      oldValue + 1
    );
    setCount((state) => state + 1);
  };

  const handleDecrease = (optionIndex: number): void => {
    const oldValue = getValues(
      `complements.${complementIndex}.options.${optionIndex}.quantity`
    );
    if (oldValue > 0) {
      setValue(
        `complements.${complementIndex}.options.${optionIndex}.quantity`,
        oldValue - 1
      );
      setCount((state) => state - 1);
    }
  };

  function optionQuantityIsNull(optionIndex: number): boolean {
    const quantity = getValues(
      `complements.${complementIndex}.options.${optionIndex}.quantity`
    );

    return quantity === 0 || quantity === undefined;
  }
 */
