import { useState } from "react";
import { Flex, Box, Button, Input } from "@chakra-ui/react";
import BeatLoader from "react-spinners/BeatLoader";

import { Control, useFormContext, useWatch } from "react-hook-form";

import { formatPrice } from "../utils/formatPrice";
import { PizzaDetailsFormData2 } from "../Pages/PizzaDetails";

function PizzaDetailFooter({
  control,
}: {
  control: Control<PizzaDetailsFormData2>;
}): JSX.Element {
  const {
    register,
    getValues,
    setValue,
    formState: { isSubmitting },
  } = useFormContext();

  const unitPrice = (getValues("unitPrice") as number) || 0;
  // const numberOfFlavors = (getValues("size.numberOfFlavors") as number) || 1;
  const [quantity, setQuantity] = useState<number>(getValues("quantity") || 1);

  let total = 0;
  let totalOptions = 0;

  total += unitPrice;

  const flavors = useWatch({
    control,
    name: "flavors",
  });

  const edge = useWatch({
    control,
    name: "edge",
  });

  const totalFlavors = flavors?.reduce((sum, flavor) => {
    if (flavor?.price) {
      return sum + flavor.price || 0;
    }

    return sum + 0;
  }, 0);

  // soma ao total os valores dos sabores
  totalOptions += totalFlavors || 0;

  // soma ao total o valor da borda
  totalOptions += edge?.price || 0;

  //
  total += totalOptions;

  total *= quantity;

  // setar price
  setValue("price", unitPrice * quantity);
  setValue("totalOptions", totalOptions);
  setValue("total", total);

  const handleIncrement = (): void => {
    const oldValue = getValues("quantity");
    setValue("quantity", oldValue + 1);
    setQuantity((state) => state + 1);
  };

  const handleDecrease = (): void => {
    const oldValue = getValues("quantity");
    if (oldValue > 1) {
      setValue("quantity", oldValue - 1);
      setQuantity((state) => state - 1);
    }
  };

  function allMandatoryIsChecked(): boolean {
    // checa se todos os sabores estao marcados
    const allFlavorsChecked = flavors?.every(
      (flavor) => flavor.id !== undefined
    );
    return allFlavorsChecked && !!edge?.id;
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "60px",
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 3000,
        borderTop: "1px solid #d5d5d5",
        background: "#ffff",
      }}
    >
      <Flex maxW="100px" mr={4}>
        <Button
          borderRadius="0"
          colorScheme="primary"
          variant="outline"
          disabled={quantity === 1}
          onClick={() => handleDecrease()}
          size="sm"
        >
          -
        </Button>

        <Input
          {...register("quantity")}
          type="number"
          size="sm"
          textAlign="center"
          readOnly
          border="none"
          borderRadius="none"
          onChange={(e) => {
            parseInt(e.target.value, 10);
          }}
        />

        <Button
          borderRadius="0"
          colorScheme="primary"
          variant="outline"
          disabled={!allMandatoryIsChecked()}
          onClick={() => handleIncrement()}
          size="sm"
        >
          +
        </Button>
      </Flex>
      <Button
        type="submit"
        colorScheme="primary"
        width="60%"
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-between"
        isLoading={isSubmitting}
        spinner={<BeatLoader size={8} color="white" />}
        disabled={!allMandatoryIsChecked()}
      >
        Adicionar
        <Box>{formatPrice(total)}</Box>
      </Button>
    </div>
  );
}

export { PizzaDetailFooter };
