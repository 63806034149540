/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import useSWR from "swr";
import { api } from "../services/api";

export function useFetch<Data = any, Error = any>(url: string) {
  const { data, error } = useSWR<Data, Error>(
    url,
    async (url) => {
      const response = await api.get(url);

      return response.data;
    }
    // { revalidateOnMount: true }
  );

  return { data, error, isLoading: !error && !data };
}
