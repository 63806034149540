/* eslint-disable react/require-default-props */
import { Flex, Img, Box, Text } from "@chakra-ui/react";

import { CgChevronRight } from "react-icons/cg";

import money_32 from "../assets/money_32.png";
import card from "../assets/credit_card.png";
import pixImage from "../assets/pix-106.png";
import { PaymentType } from "../Pages/OrderDetails";

interface PaymentInfoProps {
  title: string;
  msg: string;
  img: string;
}

function PaymentInfo({ title, msg, img }: PaymentInfoProps): JSX.Element {
  return (
    <Flex
      p="0.5rem 1rem 0.5rem 1rem"
      border="1px solid gray"
      w="full"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box>
        <Flex gap={4} alignItems="center">
          <Img src={img} alt={title} width="40px" h="18px" />
          <Text>{title}</Text>
        </Flex>
        <Text>{msg}</Text>
      </Box>
      <CgChevronRight />
    </Flex>
  );
}

function Payment({
  paymentMethod,
  change,
}: {
  paymentMethod: PaymentType;
  change?: string;
}): JSX.Element {
  switch (paymentMethod) {
    case "CASH":
      return (
        <PaymentInfo
          title="Dinheiro"
          msg={change ? `Levar troco para R$ ${change}` : "Sem troco"}
          img={money_32}
        />
      );
    case "PIX":
      return <PaymentInfo title="Pix" msg="Levar Máquina" img={pixImage} />;
    case "DEBIT_CARD":
      return (
        <PaymentInfo title="Cartão de débito" msg="Levar Máquina" img={card} />
      );
    case "CREDIT_CARD":
      return (
        <PaymentInfo title="Cartão de Crédito" msg="Levar Máquina" img={card} />
      );
    case "MEAL_TICKET":
      return (
        <PaymentInfo title="Vale Refeição" msg="Levar Máquina" img={card} />
      );
    default: {
      return (
        <Flex p={4} border="1px solid gray" w="full" justifyContent="center">
          Selecione a forma de pagamento
        </Flex>
      );
    }
  }
}

export { Payment };
