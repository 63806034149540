import { useEffect, useState } from "react";
import {
  Box,
  FormLabel,
  Textarea,
  Text,
  Input,
  useToast,
  Flex,
} from "@chakra-ui/react";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import {
  Link as LinkRouterDom,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";

import { MdOutlineArrowBack } from "react-icons/md";

import { api } from "../services/api";
import { formatPrice } from "../utils/formatPrice";
import { TComplement } from "../types";

import { useCart } from "../store/useCart";

import { ItemDetailFooter } from "../Components/ItemDetailFooter";
import { ComplementItemContainer } from "../Components/ComplementItemContainer";

export interface ItemDetailsFormData2 {
  productId: string;
  name: string;
  quantity: number;
  unitPrice: number;
  price: number; // price = quantity * unitPrice
  totalOptions: number;
  observations: string;
  complements: {
    id: string;
    name: string;
    min: number;
    max: number;
    options: {
      id: string;
      productId: string;
      name: string;
      quantity: number;
      unitPrice: number;
      price: number; // price = quantity * unitPrice
    }[];
  }[];

  total: number;
}

export function delay(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 500);
  });
}

function ItemDetails(): JSX.Element {
  const { productId } = useParams();
  const [complements, setComplements] = useState<TComplement[] | null>(null);
  const [error, setError] = useState<any>(null);
  const companyId = process.env.REACT_APP_COMPANYID;
  // const { data: complements } = useFetch<TComplement[]>(
  //   `/products/${productId}`
  // );

  useEffect(() => {
    async function fetch(): Promise<void> {
      try {
        const response = await api.get(
          `/companies/${companyId}/products/${productId}`
        );

        setComplements(response.data);
      } catch (err) {
        setError(err);
        console.log(err);
      }
    }
    fetch();
  }, [companyId, productId]);

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {});
    Events.scrollEvent.register("end", () => {});

    scrollSpy.update();

    scroll.scrollToTop({ duration: 100 });

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const addTocart = useCart((state) => state.addTocart);
  const navigate = useNavigate();
  const toast = useToast();

  // informacoes dos item
  const { name, urlImage, description, originalPrice } = useLocation()
    .state as {
    name: string;
    description: string;
    urlImage: string;
    originalPrice: number;
  };

  const metods = useForm<ItemDetailsFormData2>({
    defaultValues: {
      productId,
      name,
      unitPrice: originalPrice,
      quantity: 1,
      observations: "",
      complements: [],
    },
  });

  async function handleFormSubmit(data: ItemDetailsFormData2): Promise<void> {
    // adiciona somente as options selecionadas
    const options = data.complements?.reduce((acc, complement) => {
      const selectedOptions = complement.options.filter(
        (option) => option.quantity !== 0
      );
      return [...acc, ...selectedOptions];
    }, [] as any);

    addTocart({
      productId: data.productId,
      name: data.name,
      observations: data.observations,
      quantity: data.quantity,
      unitPrice: data.unitPrice,
      price: data.price,
      totalOptions: data.totalOptions,
      total: data.total,
      options: options || [], // pode nao haver options
    });
    // await resolverDepoisDe2Segundos();
    toast({
      title: "Item adicionado á sua sacola",
      status: "success",
      position: "bottom",
      duration: 1000,
    });
    await delay();
    navigate("/shopping-cart", { replace: true });
  }

  if (error) {
    console.log(error);
    return <div>Ocorreu um erro</div>;
  }

  return (
    <FormProvider {...metods}>
      <Box
        as="form"
        maxW="780px"
        onSubmit={metods.handleSubmit(handleFormSubmit)}
      >
        {/* componente com foto, descricao, name e preco do produto */}
        <Box
          h="200px"
          w="100%"
          bg="white"
          position="relative"
          background={`url(${urlImage}) no-repeat center`}
          backgroundSize="cover"
        >
          <Box
            position="absolute"
            top="60px"
            left="1rem"
            color="black"
            bg="white"
            borderRadius="50%"
          >
            <LinkRouterDom to="/menu">
              <MdOutlineArrowBack size={30} />
            </LinkRouterDom>
          </Box>
        </Box>
        <Box p="1rem">
          <Input // tirar esse input( nome setado defaut)
            {...metods.register("name")}
            fontSize="lg"
            fontWeight="base"
            color="gray.900"
            readOnly
            p={0}
            border="none"
          />
          <Text p={1}>{description}</Text>
          <Text p={1}>{formatPrice(originalPrice)}</Text>
          <Input
            type="hidden"
            {...metods.register(`unitPrice`, { valueAsNumber: true })}
            defaultValue={originalPrice}
          />
        </Box>

        {/* Se tiver complementos,sao listados aqui */}
        {!complements ? (
          <Flex w="100%" justifyContent="center">
            <BeatLoader size={12} color="red" />
          </Flex>
        ) : (
          <Box>
            {complements?.map((complement, complementIndex) => (
              <Element key={complement.complementId} name={complement.name}>
                <ComplementItemContainer
                  key={complement.complementId}
                  complement={complement}
                  complementIndex={complementIndex}
                />
              </Element>
            ))}

            {/* Card de observacoes( componentizar!!!!!) */}
            <Box m="1rem 0 1rem 0" p="1rem">
              <FormLabel>Observações:</FormLabel>
              <Textarea
                {...metods.register("observations")}
                placeholder="Digite aqui suas observações."
              />
            </Box>
          </Box>
        )}

        {/* espaco em branco no pe da pagina(para o footer nao tampar) */}
        <div style={{ background: "white", height: "60px", width: "100%" }} />
        <ItemDetailFooter control={metods.control} />
      </Box>
    </FormProvider>
  );
}

export { ItemDetails };

// function scrollTo(element: string): void {
//   scroller.scrollTo(element, {
//     duration: 800,
//     smooth: "easeInOutQuart",
//   });
// }
