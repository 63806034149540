import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  Input,
  Button,
  Text,
  Box,
  FormLabel,
  Radio,
  Stack,
  Flex,
  useDisclosure,
  FormControl,
  HStack,
  FormErrorMessage,
} from "@chakra-ui/react";

import { MdOutlinePlace, MdAdd } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { CgChevronLeft } from "react-icons/cg";

import { useForm } from "react-hook-form";
import { useState } from "react";
import { api } from "../services/api";
import { useUser } from "../store/useUser";

interface FormData {
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  uf: string;
  zipCode: string;
  complement: string;
  reference: string;
}

function Address(): JSX.Element {
  const [isNewAddress, setIsNewAddress] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const adresses = useUser((state) => state.adresses);
  const addAddress = useUser((state) => state.addAddress);
  const removeAddress = useUser((state) => state.removeAddress);
  const selectAddress = useUser((state) => state.selectAddress);

  const addressSelect = adresses.find((address) => address.selected === true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  async function handleSearchCep(): Promise<void> {
    try {
      const cep = getValues("zipCode");
      if (cep) {
        if (cep.replace("-", "").length < 8) {
          // cep: 99999-999  8 digits
          throw new Error();
        }
        const result = await api.get(
          `${process.env.REACT_APP_VIACEP_URL}/${cep.replace("-", "")}/json/`
        );

        if (result.data.erro) {
          setShowForm(true);
        } else {
          setValue("street", result.data.logradouro);
          setValue("neighborhood", result.data.bairro);
          setValue("city", result.data.localidade);
          setValue("uf", result.data.uf);
          setShowForm(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleFormSubmit(data: FormData): void {
    addAddress({
      ...data,
      zipCode: data.zipCode.replace("-", ""),
      selected: true,
    });
    reset();
    setIsNewAddress(false);
    setShowForm(false);
    onClose();
  }
  return (
    <>
      <Box onClick={onOpen}>
        {addressSelect ? (
          <Flex alignItems="center" gap={2}>
            <MdOutlinePlace />
            {`${addressSelect.street}, ${addressSelect.number}`}
          </Flex>
        ) : (
          <Flex alignItems="center" gap={2}>
            <FiSend />
            Selecione um endereço
          </Flex>
        )}
      </Box>
      <Drawer isOpen={isOpen} placement="bottom" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader mt={16}>
            <Flex alignItems="center" gap={12}>
              <CgChevronLeft
                size={25}
                onClick={() => {
                  reset();
                  setIsNewAddress(false);
                  setShowForm(false);
                  onClose();
                }}
              />
              Endereço para entrega
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {!isNewAddress ? (
              <>
                <Flex>
                  <Button
                    leftIcon={<MdAdd size={20} />}
                    colorScheme="primary"
                    variant="outline"
                    isFullWidth
                    onClick={() => setIsNewAddress(true)}
                  >
                    Adicionar Endereço
                  </Button>
                </Flex>
                <Text mt={8} size="lg" fontWeight="bold">
                  Meus Endereços
                </Text>

                <Stack direction="column">
                  {adresses.map((address) => (
                    <Flex key={address.id} alignItems="center">
                      <Radio
                        size="lg"
                        colorScheme="primary"
                        isChecked={address.selected}
                        onClick={() => selectAddress(address.id)}
                      />
                      <Box p={4} fontSize="16px">
                        <Text>{`${address.street}, ${address.number} - ${address.complement}`}</Text>
                        <Text>{`${address.neighborhood}, ${address.city} - ${address.uf} - ${address.zipCode}`}</Text>
                      </Box>
                      <RiDeleteBinLine
                        size={25}
                        cursor="pointer"
                        onClick={() => removeAddress(address.id)}
                      />
                    </Flex>
                  ))}
                </Stack>
              </>
            ) : (
              <Stack
                as="form"
                onSubmit={handleSubmit(handleFormSubmit)}
                spacing={4}
              >
                <Flex>
                  <Input
                    {...register("zipCode")}
                    placeholder="Informe o CEP"
                    onChange={(e) => {
                      let { value } = e.currentTarget;
                      e.currentTarget.maxLength = 9;
                      value = value.replace(/\D/g, "");
                      value = value.replace(/(\d{5})(\d)/, "$1-$2");
                      e.currentTarget.value = value;
                      return value;
                    }}
                  />
                  <Button
                    colorScheme="primary"
                    ml={2}
                    onClick={() => handleSearchCep()}
                  >
                    Buscar
                  </Button>
                </Flex>
                <Button variant="link" onClick={() => setShowForm(true)}>
                  Não sei meu cep
                </Button>
                {showForm && (
                  <>
                    <FormControl isInvalid={!!errors.street}>
                      <FormLabel>Endereço</FormLabel>
                      <Input
                        {...register("street", {
                          required: "Campo obrigatório",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.street?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <HStack>
                      <FormControl isInvalid={!!errors.number}>
                        <FormLabel>Número</FormLabel>
                        <Input
                          {...register("number", {
                            required: "Campo obrigatório",
                          })}
                        />
                        <FormErrorMessage>
                          {errors.number?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl>
                        <FormLabel>Complemento</FormLabel>
                        <Input {...register("complement")} />
                      </FormControl>
                    </HStack>
                    <FormControl isInvalid={!!errors.neighborhood}>
                      <FormLabel>Bairro</FormLabel>
                      <Input
                        {...register("neighborhood", {
                          required: "Campo obrigatório",
                        })}
                      />
                      <FormErrorMessage>
                        {errors.neighborhood?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Ponto de Referência</FormLabel>
                      <Input {...register("reference")} />
                    </FormControl>
                    <HStack>
                      <FormControl isInvalid={!!errors.city}>
                        <FormLabel>Cidade</FormLabel>
                        <Input
                          {...register("city", {
                            required: "Campo obrigatório",
                          })}
                        />
                        <FormErrorMessage>
                          {errors.city?.message}
                        </FormErrorMessage>
                      </FormControl>
                      <FormControl isInvalid={!!errors.uf}>
                        <FormLabel>UF</FormLabel>
                        <Input
                          {...register("uf", { required: "Campo obrigatório" })}
                        />
                        <FormErrorMessage>
                          {errors.uf?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </HStack>
                    <Button type="submit" colorScheme="primary" isFullWidth>
                      Salvar endereço
                    </Button>{" "}
                  </>
                )}
              </Stack>
            )}
          </DrawerBody>

          <DrawerFooter />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export { Address };
